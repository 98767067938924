
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import smsMixin from '@/mixins/sms'
import GlobalMixin from '@/mixins/global'
import { mobileRegExp, emailRegExp, passwordRegExp } from '@/utils/reg'
import { Base64 } from 'js-base64'
import { inBenlaiApp, bridge, dsBridge } from '@/utils/env'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'changePassword',
  components: {
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class ChangePasswordPage extends Vue {
  @Provide() public account: string = ''
  @Provide() public code: string = ''
  @Provide() public secondPass: string = ''
  @Provide() public codeError: string = ''
  @Provide() public apiError: string = ''
  @Provide() public waitTime: number = 2
  @Provide() public phone: string = ''
  @Provide() public secondPassInputType: string = 'password'
  @Provide() public autoFocus: boolean = false
  @Provide() public codeFocus: boolean = false
  @Provide() public secondPassFocus: boolean = false
  @Provide() public step: number = 1
  @Provide() public verifyCode: string = ''
  @Provide() public isNew: boolean = false // 是否新增密码
  @Provide() public redirect: string = ''
  @Emit()
  public created () {
    this.redirect = decodeURIComponent(getCurrentQuery('redirect') || '')
    this.isNew = getCurrentQuery('isNew') === '0'
    this.refresh()
  }

  @Emit()
  public async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'Password/ResetChannel',
        data: {
          type: 1
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.phone = res.data.phone || ''
      }
    } catch (err) {
      if (err.error === '107') {
        // 未绑定安全手机
        this.$toasted.show('绑定手机号后才可以修改登录密码，请先去绑定手机号哦')
        setTimeout(() => {
          this.$router.back()
        }, 1500)
      }
      this.codeError = err.message
    }
  }

  @Emit()
  public sendCode () {
    if (this.remainingSecond > 0 && this.remainingSecond < 61) {
      return
    }
    (this.$refs.code as any).focus()
    this.sendSMS()
  }

  @Emit()
  public async sendSMS () {
    let isSecend = 0
    const [err, res] = await this.sendV5SMSCode(this.phone, 6, 0)
    console.log(err, res)
    if (err && err.error && err.message) {
      this.codeError = err.message
    }
  }

  @Emit()
  public inputPassword (type: number) {
    const reg = /[\u4e00-\u9fa5]/g
    this.secondPass = this.secondPass.replace(reg, '')
  }

  @Emit()
  public passwordSecondError () {
    this.apiError = ''
    setTimeout(() => {
      this.secondPassFocus = false
    }, 150)
    if (this.secondPass.length < 8 || this.secondPass.length > 16) {
      this.apiError = '密码长度需要在8-16位'
      return
    }
    if (!passwordRegExp.test(this.secondPass)) {
      this.apiError = '密码至少须包含数字/字母/符号中的2种'
      return
    }
    this.apiError = ''
  }

  @Emit()
  private inputBlur () {
    this.codeError = ''
    this.code = this.code ? String(parseInt(this.code) || '') : ''
    setTimeout(() => {
      this.codeFocus = false
    }, 150)
  }

  @Emit()
  private showPass (type: string) {
    this.secondPassInputType = this.secondPassInputType === 'password' ? 'text' : 'password'
  }
  @Emit()
  public async next () {
    const self = this
    if (!this.code) {
      this.codeError = '验证码不能为空'
      return
    }
    if (this.code.length !== 6) {
      this.codeError = '验证码错误，请重新输入'
      return
    }
    if (this.codeError) {
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'Password/CheckSmsCode',
        data: {
          type: 1,
          smsCode: this.code
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.verifyCode = res.data.token
        this.$toasted.show('认证成功')
        setTimeout(() => {
          self.step = 2
        }, 1000)
      }
    } catch (err) {
      if (err.message === 'Network Error') {
        this.$toasted.show('网络异常，请重新提交')
      } else {
        this.codeError = err.message
      }
    }
  }

  @Emit()
  public async submit () {
    const self = this
    if (!this.secondPass) {
      this.apiError = '密码不能为空'
      return
    }
    if (this.secondPass.length < 8 || this.secondPass.length > 16) {
      this.apiError = '密码长度需要在8-16位'
      return
    }
    if (!passwordRegExp.test(this.secondPass)) {
      this.apiError = '密码至少须包含数字/字母/符号中的2种'
      return
    }
    if (this.apiError) {
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'Password/Reset',
        data: {
          type: 1,
          token: this.verifyCode,
          password: this.secondPass
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('修改成功')
        setTimeout(() => {
          self.step = 3
        }, 1000)
      }
    } catch (err) {
      if (err.message === 'Network Error') {
        this.$toasted.show('网络异常，请重新提交')
      } else {
        this.apiError = err.message
      }
    }
  }

  @Emit()
  public goback () {
    console.log('这里执行了返回')
    const self = this
    if (this.step === 2) {
      this.code = ''
      this.secondPass = ''
      this.codeError = ''
      this.apiError = ''
      this.step = 1
      this.clearCountDown()
    } else if (this.step === 3) {
      console.log('111')
      if (inBenlaiApp) {
        console.log('444')
        dsBridge.call('getPublicParticipation', '', (res: any) => {
          const publicInfo = JSON.parse(res)
          let version = publicInfo.version
          if (this.compareVersion(version, '9.0.5')) {
            console.log('555')
            dsBridge.call('logoutAction', '', (res: any) => {
              console.log('logoutAction', res)
              console.log('777')
              if (res === 'true') {
                console.log('888')
                dsBridge.call('loginAction', self.redirect.split('?')[0])
              }
            })
          } else {
            console.log('666')
            bridge.goback()
          }
        })
      } else {
        console.log('333')
        location.replace(`/sms?source=${this.source}&redirect=${self.redirect}`)
      }
    } else {
      console.log('222')
      location.replace(self.redirect)
    }
  }
}
