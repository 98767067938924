
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

const PAGE_SIZE = 10

const w = window as any
const screenHeight: number = w.document.documentElement.clientHeight

interface ApplyOrderListData {
  orderType ?: number
  year ?: number
  month ?: number
  invoiceSearchType ?: number
  pageIndex ?: number
  pageSize ?: number
}
interface DateOption {
  months: number[]
  year: number
}
interface OrderTypeOption {
  name: string
  type: number
}

@Component({
  name: 'InvoiceMultiple',
  components: {
    swiper,
    swiperSlide
  },
  mixins: [ GlobalMixin ]
})

export default class Authorization extends Vue {
  public name = 'InvoiceMultiple'

  private isMultipleShow = false
  private isEmpty = false
  private isLoading = false
  private isLoadEnd = false
  private pageIndex = 1
  private containEl :any = null

  private invoiceData :any[] = [] // 发票数据

  private isCheckedAll = false

  private isTypeSelectorVisible = false
  private isTimeSelectorVisible = false
  private timeSelectorText = '选择订单时间'

  private typeSelectorValue = 1
  private monthConfirmValue = 0
  private yearConfirmValue = 0

  private dateOptions: Array<DateOption> = []
  private orderTypeOptions: Array<OrderTypeOption> = []

  private swiperOptions = {
    direction: 'vertical',
    slidesPerView: 4,
    centeredSlides: true
  }

  private yearIndex = 0
  private monthIndex = 0

  get typeSelectorText () {
    if (this.orderTypeOptions.length) {
      const option = this.orderTypeOptions.find((item: OrderTypeOption) => {
        return item.type === this.typeSelectorValue
      })
      if (option) {
        return option.name
      }
    }
    return '普通订单'
  }

  get yearSwiper () {
    return (this.$refs.yearSwiper as any).swiper
  }

  get monthSwiper () {
    return (this.$refs.monthSwiper as any).swiper
  }

  get yearArray () {
    return [{ text: '全部', value: 0 }, ...this.dateOptions.map((item: any) => {
      return {
        text: item.year + '年',
        value: item.year
      }
    })]
  }

  get yearSelectorValue () {
    if (this.yearArray.length) {
      return this.yearArray[this.yearIndex].value
    }
    return 0
  }

  get yearSelectorText () {
    if (this.yearArray.length) {
      return this.yearArray[this.yearIndex].text
    }
    return ''
  }

  get monthArray () {
    const yearValue = this.yearSelectorValue
    if (yearValue === 0) {
      return []
    }
    const yearItem: any = this.dateOptions.find((option: any) => {
      return option.year === yearValue
    })
    return [{ text: '全部', value: 0 }, ...yearItem.months.map((month: any) => {
      return {
        text: month + '月',
        value: month
      }
    })]
  }

  get monthSelectorValue () {
    if (this.monthArray.length) {
      return this.monthArray[this.monthIndex].value
    }
    return 0
  }

  get monthSelectorText () {
    if (this.monthArray.length) {
      return this.monthArray[this.monthIndex].text
    }
    return ''
  }

  get selectSoid () {
    const selectSoid: any[] = []
    this.invoiceData.forEach(item => {
      item.selected && selectSoid.push(item.soId)
    })
    return selectSoid
  }

  @Watch('typeSelectorValue')
  @Watch('yearConfirmValue')
  @Watch('monthConfirmValue')
  private watchSelector () {
    this.pageIndex = 1
    this.invoiceData = []
    this.isEmpty = false
    this.isLoadEnd = false
    this.isCheckedAll = false

    this.refreshData()
  }

  private created () {
    this.getInvoiceOption()
    this.refreshData()
  }

  private mounted () {
    this.scrollInit()
  }

  private refreshData () {
    this.refreshInvoiceList({
      orderType: this.typeSelectorValue,
      year: this.yearConfirmValue,
      month: this.monthConfirmValue,
      invoiceSearchType: 1
    })
  }

  private async refreshInvoiceList (data: ApplyOrderListData = {}) {
    this.isLoading = true
    try {
      const res = await httpHelper.get({
        url: 'Invoice/ApplyOrderList',
        data: {
          pageSize: PAGE_SIZE,
          pageIndex: this.pageIndex++,
          ...data
        },
        type: 'apiv5'
      })
      console.log(res)
      if (!res.data || !res.data.length) {
        this.isLoadEnd = true
      } else {
        this.invoiceData = [...this.invoiceData, ...res.data]

        if (res.data.length < PAGE_SIZE) {
          this.isLoadEnd = true
        }
      }

      !this.invoiceData.length && (this.isEmpty = true)
    } catch (err) {
      this.showErrorToast(err)
    } finally {
      this.isLoading = false
    }
  }

  private async getInvoiceOption () {
    try {
      const res = await httpHelper.get({
        url: 'Invoice/Option',
        data: {},
        type: 'apiv5'
      })
      console.log(res)
      const { dateOptions, orderTypeOptions } = res.data
      if (dateOptions.length) {
        this.dateOptions = dateOptions
      }
      if (orderTypeOptions.length) {
        this.orderTypeOptions = orderTypeOptions
        this.typeSelectorValue = orderTypeOptions[0].type
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  private slideChangeYear (e: any) {
    this.yearIndex = this.yearSwiper.realIndex
    this.monthSwiper.slideTo(0)
  }

  private slideChangeMonth (e: any) {
    this.monthIndex = this.monthSwiper.realIndex
  }

  private timeSelectorConfirm () {
    this.timeSelectorText = this.yearSelectorText + this.monthSelectorText
    this.yearConfirmValue = this.yearSelectorValue
    this.monthConfirmValue = this.monthSelectorValue
    this.isTimeSelectorVisible = false
  }

  private typeSelectorConfirm (type: number) {
    this.typeSelectorValue = type
    this.isTypeSelectorVisible = false
  }

  private closeAllSelector () {
    this.isTypeSelectorVisible = false
    this.isTimeSelectorVisible = false
  }

  private propInvoiceStatus (invoice: any) {
    this.$set(invoice, 'selected', !invoice.selected)
    this.checkSelectAll()
  }

  private selectAll () {
    const checkedTmp = this.isCheckedAll
    this.isCheckedAll = !checkedTmp
    this.invoiceData.forEach(item => {
      this.$set(item, 'selected', !checkedTmp)
    })
  }

  private checkSelectAll () {
    const isCheckedAll = this.invoiceData.every(item => item.selected)
    this.isCheckedAll = isCheckedAll
  }

  private makeInvoice () {
    const selectSoid: any[] = this.selectSoid
    if (!selectSoid.length) {
      this.$toasted.show('请选择至少一个发票')
      return
    }
    this.$router.push({ path: `/invoiceSet?soids=${selectSoid.join(',')}&type=${this.typeSelectorValue}` })
  }

  private scrollInit () {
    this.containEl = this.$refs.InvoiceBody
    window.addEventListener('scroll', this.fetchScrollTop, false)
  }

  private fetchScrollTop () {
    if (!this.containEl) {
      return
    }
    const scrollTop = document.documentElement.scrollTop || window.pageYOffset
    const offsetHeight = this.containEl.clientHeight
    // console.log(scrollTop + screenHeight, offsetHeight, this.isLoadEnd, this.isLoading)
    if ((scrollTop + screenHeight) >= offsetHeight && !this.isLoadEnd && !this.isLoading) {
      this.refreshData()
    }
  }
}
