
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import smsMixin from '@/mixins/sms'
import { mobileRegExp } from '@/utils/reg'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'changePhone',
  components: {
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class member extends Vue {
  @Provide() public prePhone: string = ''
  @Provide() public newPhone: string = ''
  @Provide() public code: string = ''
  @Provide() public isValidateOldPhone: boolean = true
  @Provide() public foremError: string = ''
  @Provide() public token: string = ''
  @Provide() public inputFocus: boolean = false
  @Provide() public showSuccessDialog: boolean = false

  get formValidate () {
    if (this.isValidateOldPhone) {
      return !!this.code
    } else {
      return !!this.newPhone && !!this.code
    }
  }

  public created () {
    this.getUserPhone()
  }

  private async getUserPhone () {
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/index',
        type: 'apiv5'
      })
      const result = res.data || {}
      this.prePhone = result.safePhoneShow || ''
    } catch (err) {
      console.log(err)
    }
  }

  @Emit()
  private async sendCode (type: number) {
    let mobile = this.newPhone
    let isSecend = 0
    const smsType = type
    if (type === 5 && this.remainingSecond) {
      return
    }
    if (type !== 5 && this.secondForChangePhone) {
      return
    }
    if (type === 5) {
      const [err, res] = await this.sendV5SMSCode(mobile, smsType, isSecend)
      if (err && err.error && err.message) {
        this.foremError = err.message
      }
    } else {
      const [err, res] = await this.validateOldPhoneSMSCode(isSecend)
      if (err && err.error && err.message) {
        this.foremError = err.message
      }
    }
  }

  @Emit()
  private async validateOldPhone () {
    if (!this.formValidate) {
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'IAccount/ValidateOldPhone',
        data: {
          code: this.code
        },
        type: 'apiv5'
      })
      console.log(res)
      const result = res.data || {}
      if (result.token) {
        this.isValidateOldPhone = false
        this.code = ''
        this.foremError = ''
        this.token = result.token || ''
      }
    } catch (err) {
      console.log(err)
      this.foremError = err.message
    }
  }

  @Emit()
  private async bindNewPhone () {
    if (!this.formValidate) {
      return
    }
    this.foremError = this.mobileError()
    if (this.foremError) {
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'IAccount/BindNewPhone',
        data: {
          code: this.code,
          phone: this.newPhone,
          token: this.token
        },
        type: 'apiv5'
      })
      this.showSuccessDialog = true
    } catch (err) {
      this.foremError = err.message
      console.log(err)
    }
  }

  public mobileError () {
    if (!this.newPhone) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.newPhone) ? '' : '手机号格式不正确，请重新输入'
  }

  @Emit()
  private async goBack () {
    this.$router.back()
  }

  @Emit()
  private clearCode () {
    this.code = ''
  }

  @Emit()
  private inputBlur () {
    setTimeout(() => {
      this.inputFocus = false
    }, 150)
  }
}

