
import { Component, Vue, Provide, Emit, Watch } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import AddressModule from '@/store/modules/address'
import InvoiceModule from '@/store/modules/invoice'
import { setSelectedAddress } from '@/utils/cookies'

@Component({
  name: 'invoiceSet',
  mixins: [GlobalMixin]
})
export default class extends Vue {
  private accountsBank = ''
  private bankAccount = ''
  private companyAddress = ''
  private companyPhone = ''
  private invoiceTaxNo = ''
  private invoiceTitle = ''
  private invoiceBelong = 0
  private isShowTips: boolean = false
  private email: string = ''
  private soIds: string = ''
  private orderType: string = ''
  private from: string = '' // 来源

  private invoiceTitleList: any = []
  private invoiceInfo: any = {}
  private invoiceTypeList: any = [{}]

  private orderInvoiceTypeList: any = [] // 随单票
  private selectedOrderInvoiceType: any = null
  private orderInvoiceType = 0 // 随单票
  private emailDefault = '' // 默认邮箱（接口返回的邮箱）

  get invoiceTitleText () {
    switch (this.invoiceBelong) {
      case 1:
        return '个人'
      case 2:
        return '公司'
      default:
        return '请选择'
    }
  }

  get isOrderEnv () {
    return this.from === 'confirm'
  }

  get isCenterEnv () {
    return !this.isOrderEnv && Boolean(this.soIds && this.orderType)
  }

  get isInvoiceInfoVisible () {
    if (this.isOrderEnv) {
      return this.orderInvoiceType !== 0
    }
    if (this.isCenterEnv) {
      return Boolean(this.invoiceTypeList[0].type)
    }
    return false
  }

  get isEmailVisible () {
    if (this.isOrderEnv) {
      return this.orderInvoiceType === 5
    }
    return this.isCenterEnv
  }

  @Watch('orderInvoiceType')
  private orderInvoiceTypeWatch (val: number) {
    if (val === 5 && !this.email) {
      this.email = this.selectedOrderInvoiceType.receiveEmail
      this.emailDefault = this.email
    }
  }

  private created () {
    const soids: any = this.$route.query.soids
    const type: any = this.$route.query.type
    const from: any = this.$route.query.from
    if (!(soids && type) && !from) {
      window.history.back()
      return
    }
    if (from) {
      this.from = from
      this.getOrderInvoiceType()
      const refresh: any = this.$route.query.refresh
      if (refresh === 'true') {
        this.$toasted.show('发票信息已变更')
      }
      return
    }
    this.soIds = soids.split(',')
    this.orderType = type
    this.getInvoiceInfo()
  }

  private mounted () {
    this.refreshInvoiceHead()
  }

  private backOrder () {
    window.location.replace(`https://mc.benlai.com/order/confirm`)
  }

  private async getInvoiceInfo () {
    try {
      const res = await httpHelper.get({
        url: 'Invoice/Info',
        data: {
          soIds: JSON.stringify(this.soIds),
          orderType: this.orderType
        },
        type: 'apiv5'
      })
      this.invoiceInfo = res.data
      this.invoiceTypeList = res.data.invoiceTypeList
      this.invoiceBelong = res.data.invoiceType
      this.invoiceTaxNo = res.data.taxPayerNo
      this.invoiceTitle = res.data.invoiceTitle
      this.accountsBank = res.data.accountsBank
      this.bankAccount = res.data.bankAccount
      this.companyAddress = res.data.companyAddress
      this.companyPhone = res.data.companyPhone
      this.email = res.data.invoiceEmail
    } catch (err) {
      this.showErrorToast(err)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1500)
    }
  }

  private async refreshInvoiceHead () {
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/Invoice',
        type: 'apiv5'
      })
      console.log(res)
      if (res.data && res.data.length) {
        this.invoiceTitleList = res.data
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  private openTips () {
    this.isShowTips = true
  }

  private closeTips () {
    this.isShowTips = false
  }

  private selectTitle (item:any = {}) {
    this.invoiceTaxNo = item.invoiceTaxNo || ''
    this.invoiceTitle = item.invoiceTitle || ''
    this.invoiceBelong = item.invoiceBelong || 1
    this.accountsBank = item.accountsBank
    this.bankAccount = item.bankAccount
    this.companyAddress = item.companyAddress
    this.companyPhone = item.companyPhone
    if (item.eInvoiceEmail) {
      this.email = item.eInvoiceEmail
    }
    this.closeTips()
  }

  private openEdit (sysNo: number = 0, type: number = 0) {
    this.isShowTips = false
    let path = `/invoiceTitle/${sysNo}/${type}`
    if (this.isOrderEnv) {
      path += `?from=${this.from}`
      if (this.emailDefault) {
        path += `&eCode=${btoa(this.emailDefault)}`
      }
      window.location.replace(path)
    } else {
      this.$router.push({ path })
    }
  }

  private async submitInvoice () {
    if (!Object.keys(this.invoiceInfo).length) {
      this.$toasted.show('请等待发票信息加载完毕')
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'Invoice/ApplyInvoices',
        data: {
          soIds: this.soIds,
          invoiceBelong: this.invoiceBelong,
          invoiceType: this.invoiceTypeList[0].type,
          invoiceTitle: this.invoiceTitle,
          invoiceTaxNo: this.invoiceTaxNo,
          invoiceAmount: this.invoiceInfo.invoiceAmount || '',
          bankAccount: this.bankAccount,
          accountsBank: this.accountsBank,
          companyAddress: this.companyAddress,
          companyPhone: this.companyPhone,
          email: this.email || '',
          orderType: this.orderType
        },
        type: 'apiv5'
      })
      console.log(res)
      this.$toasted.show('开票成功')
      setTimeout(() => {
        this.$router.go(-1)
      }, 1500)
    } catch (err) {
      // AddressModule.setAddress(0)
      // InvoiceModule.setInvoice(0)
      this.showErrorToast(err)
    }
  }

  private async submitOrderInvoice () {
    if (!this.orderInvoiceTypeList.length) {
      this.$toasted.show('请等待发票信息加载完毕')
      return
    }
    if (this.orderInvoiceType !== 0 && this.invoiceBelong === 0) {
      this.$toasted.show('请选择发票抬头信息')
      return
    }
    const emailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]+$/
    if (this.orderInvoiceType === 5 && !emailReg.test(this.email)) {
      this.$toasted.show('请填写正确的邮箱信息')
      return
    }
    let data: any = {
      invoiceType: this.orderInvoiceType
    }
    if (this.orderInvoiceType !== 0) {
      data = {
        ...data,
        requestInvoiceType: this.invoiceBelong,
        invoiceContent: this.invoiceTitle,
        taxPayerNo: this.invoiceTaxNo,
        receiveEmail: this.email,
        bankAccount: this.bankAccount,
        accountsBank: this.accountsBank,
        companyAddress: this.companyAddress,
        companyPhone: this.companyPhone
      }
    }
    try {
      const res = await httpHelper.post({
        url: 'IShopping/Invoice',
        data,
        type: 'apiv5'
      })
      window.location.replace(`https://mc.benlai.com/order/confirm`)
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  private async getOrderInvoiceType () {
    try {
      const res = await httpHelper.get({
        url: 'IOrder/InvoiceType',
        type: 'apiv5'
      })
      const data = res.data
      if (!data || !data.invoiceList || !data.invoiceList.length) {
        window.location.replace(`https://mc.benlai.com/order/confirm`)
        return
      }
      this.orderInvoiceTypeList = data.invoiceList
      data.invoiceList.some((invoice: any) => {
        if (invoice.checked) {
          this.orderInvoiceType = invoice.type
          this.selectedOrderInvoiceType = invoice
          return true
        }
      })
      const { defaultInvoice } = data
      this.invoiceBelong = defaultInvoice.invoiceBelong
      this.invoiceTaxNo = defaultInvoice.invoiceTaxNo || ''
      this.invoiceTitle = defaultInvoice.invoiceTitle || ''
      this.accountsBank = defaultInvoice.accountsBank || ''
      this.bankAccount = defaultInvoice.bankAccount || ''
      this.companyAddress = defaultInvoice.companyAddress || ''
      this.companyPhone = defaultInvoice.companyPhone || ''
    } catch (err) {
      this.showErrorToast(err)
    }
  }
}

