import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import httpHelper from '@/utils/request'
import store from '@/store'

export interface InvoiceState {
  invoice: any[] | null
  invoiceSysNo: number
}

@Module({ dynamic: true, store, name: 'invoice' })
class Invoice extends VuexModule implements InvoiceState {
  public invoice: any[] | null = null
  public invoiceSysNo: number = 0

  @Mutation
  private SET_INVOICE (invoice: any[]) {
    this.invoice = invoice
  }

  @Mutation
  private SET_SELECTED_INVOICE (sysNo: number) {
    this.invoiceSysNo = sysNo
  }

  @Action({ commit: 'SET_INVOICE', rawError: true })
  public async loadInvoiceList () {
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/Invoice',
        type: 'apiv5'
      })
      return res.data || []
    } catch (err) {
      return []
    }
  }

  @Action({ commit: 'SET_SELECTED_INVOICE', rawError: true })
  public async setInvoice (sysNo: number) {
    return sysNo
  }
}

const InvoiceModule = getModule(Invoice)

export default InvoiceModule
