
import { Component, Vue, Provide, Emit, Watch } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import AddressModule from '@/store/modules/address'
import InvoiceModule from '@/store/modules/invoice'
import { setSelectedAddress } from '@/utils/cookies'
import Multiple from '@/components/invoice/Multiple.vue'

const PAGE_SIZE = 10

const w = window as any
const screenHeight: number = w.document.documentElement.clientHeight

interface ApplyOrderListData {
  orderType ?: number
  year ?: number
  month ?: number
  invoiceSearchType ?: number
  pageIndex ?: number
  pageSize ?: number
}

@Component({
  name: 'invoiceCenter',
  mixins: [GlobalMixin],
  components: {
    'invoice-multiple': Multiple
  }
})
export default class extends Vue {
  private isMultipleShow = false
  private isEmpty = false
  private isLoading = false
  private isLoadEnd = false
  private pageIndex = 1
  private containEl :any = null

  private invoiceHead :any[] = [] // 发票抬头
  private invoiceData :any[] = [] // 发票数据

  private status = -1 // 1:全部 2:申请记录 3:抬头管理 4:批量管理

  private touchKey = 0
  private startPoint: any = { x: 0, y: 0 }
  private deltaX = 0

  private created () {
    this.currentRouteChanged()
  }

  private mounted () {
    this.scrollInit()
  }

  @Watch('status')
  private refreshData (status: number) {
    this.isEmpty = false
    this.isLoadEnd = false
    this.pageIndex = 1
    this.invoiceData = []
    this.invoiceHead = []

    this.loadDataList()
  }

  @Watch('$route')
  private currentRouteChanged () {
    const { id } = this.$route.params
    this.status = parseInt(id) || 0
  }

  private loadDataList () {
    switch (this.status) {
      case 0:
        this.refreshInvoiceList()
        break
      case 1:
        this.refreshInvoiceList({
          invoiceSearchType: 2
        })
        break
      case 2:
        this.refreshInvoiceHead()
    }
  }

  private async refreshInvoiceList (data: ApplyOrderListData = {}) {
    this.isLoading = true
    try {
      const res = await httpHelper.get({
        url: 'Invoice/ApplyOrderList',
        data: {
          pageSize: PAGE_SIZE,
          pageIndex: this.pageIndex++,
          ...data
        },
        type: 'apiv5'
      })
      console.log(res)
      if (!res.data || !res.data.length) {
        this.isLoadEnd = true
      } else {
        this.invoiceData = [...this.invoiceData, ...res.data]

        if (res.data.length < PAGE_SIZE) {
          this.isLoadEnd = true
        }
      }

      !this.invoiceData.length && (this.isEmpty = true)
    } catch (err) {
      this.showErrorToast(err)
    } finally {
      this.isLoading = false
    }
  }

  private async refreshInvoiceHead () {
    this.isLoading = true
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/Invoice',
        type: 'apiv5'
      })
      console.log(res)
      if (res.data && res.data.length) {
        this.invoiceHead = res.data
      }
      this.isLoadEnd = true
      if (this.invoiceHead.length) {
        setTimeout(() => {
          this.bindTouchOption()
        })
      } else {
        this.isEmpty = true
      }
    } catch (err) {
      this.showErrorToast(err)
    } finally {
      this.isLoading = false
    }
  }

  private async deleteInvoiceHead (invoiceSysNo: number) {
    try {
      await httpHelper.delete({
        url: 'IUserHome/Invoice',
        data: { invoiceSysNo },
        type: 'apiv5'
      })
      this.$toasted.show('发票抬头删除成功')
      this.refreshData(this.status)
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  private tryDeleteInvoiceHead (invoiceSysNo: number) {
    let message = {
      title: '',
      body: '确定要删除此发票抬头？'
    }
    let options = {
      html: true,
      okText: '确定',
      cancelText: '取消'
    }
    this.$dialog.confirm(message, options)
      .then(() => {
        this.deleteInvoiceHead(invoiceSysNo)
        this.deltaX = 0
      })
      .catch(() => {})
  }

  private dotAmtFilter (amt: string) {
    const str = amt.match(/\d+/g) || ['0', '00']
    return {
      int: str[0],
      dec: str[1] || '00'
    }
  }

  private openEditInvoiceHead (sysNo: number, invoicetype: number) {
    this.$router.push({ path: `/invoiceTitle/${sysNo}/${invoicetype}` })
  }

  private createInvoiceHead () {
    this.$router.push({ path: `/invoiceTitle/0/0` })
  }

  private switchStatus (status: number) {
    if (this.status === status) {
      return
    }
    this.status = status
    if (status === 3) {
      this.$router.push({ path: `/invoiceCenter/${status}` })
    } else {
      this.$router.replace({ path: `/invoiceCenter/${status}` })
    }
  }

  private makeInvoice (soId: string, type: number) {
    this.$router.push({ path: `/invoiceSet?soids=${soId}&type=${type}` })
  }

  private goInvoiceDetail (soId: string) {
    this.$router.push({ path: `/invoiceDetail/${soId}` })
  }

  private bindTouchOption () {
    (this.$refs['title'] as any).forEach((el: any, key: number) => {
      if (el.bindedTouch) {
        return
      }
      el.bindedTouch = true
      el.addEventListener('touchstart', this.touchStart, false)
      el.addEventListener('touchmove', (e: any) => this.touchMove(e, key), false)
      el.addEventListener('touchend', this.touchEnd, false)
    })
  }

  private touchStart (e:any) {
    const touches = e.changedTouches[0]
    this.startPoint = {
      x: touches.pageX,
      y: touches.pageY
    }
  }

  private touchMove (e:any, key: number) {
    if (this.touchKey !== key) {
      this.deltaX = 0
    }
    this.touchKey = key
    const touches = e.changedTouches[0]
    const delta = {
      x: touches.pageX - this.startPoint.x,
      y: touches.pageY - this.startPoint.y
    }
    // 横向位移大于纵向位移，阻止纵向滚动
    if (Math.abs(delta.x) > Math.abs(delta.y)) {
      e.preventDefault()
    }
  }

  private touchEnd (e:any) {
    // console.log(e)
    const diffX = e.changedTouches[0].pageX - this.startPoint.x
    if (diffX < -50) {
      // console.log('向左')
      this.deltaX = -1.4
    } else if (diffX > 50) {
      // console.log('向右')
      this.deltaX = 0
    }
  }

  private scrollInit () {
    const el: any = this.$refs.InvoiceBody

    this.containEl = el

    window.addEventListener('scroll', this.fetchScrollTop, false)
  }

  private fetchScrollTop () {
    if (!this.containEl) {
      return
    }
    const scrollTop = document.documentElement.scrollTop || window.pageYOffset
    const offsetHeight = this.containEl.clientHeight
    // console.log(scrollTop + screenHeight, offsetHeight, this.isLoadEnd, this.isLoading)
    if ((scrollTop + screenHeight) >= offsetHeight && !this.isLoadEnd && !this.isLoading) {
      this.loadDataList()
    }
  }
}

