var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modify"},[(!_vm.isABC())?_c('div',{staticClass:"modify__head"},[_c('a',{staticClass:"modify__head-back",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.goBack}})]):_vm._e(),_c('div',{staticClass:"modify__content"},[(_vm.isValidateOldPhone)?_c('div',{staticClass:"modify__item"},[_c('div',{staticClass:"modify__title"},[_c('div',{staticClass:"modify__title-word"},[_vm._v("安全手机修改")]),_c('div',{staticClass:"modify__title-explain"},[_vm._v("请输入"+_vm._s(_vm.prePhone)+"收到的短信验证码")])]),_c('ul',{staticClass:"modify__sms"},[_c('li',{staticClass:"modify__sms-list"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"modify__sms--code passport-all__list-input",attrs:{"type":"tel","placeholder":'请输入验证码',"maxLength":6},domProps:{"value":(_vm.code)},on:{"focus":function($event){_vm.inputFocus = true},"blur":_vm.inputBlur,"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}}),_c('a',{staticClass:"modify__sms-btn allbtn-xl",class:{
              'allbtn-green': !_vm.secondForChangePhone,
              'allbtn-gray': _vm.secondForChangePhone
            },attrs:{"href":"javascript:void(0)"},on:{"click":_vm.sendCode}},[_vm._v(_vm._s(_vm.smsTextForChangePhone))]),(_vm.inputFocus)?_c('a',{staticClass:"modify__sms-close",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.clearCode}}):_vm._e(),(_vm.foremError)?_c('a',{staticClass:"modify__sms-error",attrs:{"href":"javascript:void(0)"}},[_vm._v("*"+_vm._s(_vm.foremError))]):_vm._e()])]),_c('div',{staticClass:"modify__btn"},[_c('a',{staticClass:"allbtn-xl",class:{'allbtn-green': _vm.formValidate, 'allbtn-gray': !_vm.formValidate},attrs:{"href":"javascript:void(0)"},on:{"click":_vm.validateOldPhone}},[_vm._v("提交")])])]):_vm._e(),(!_vm.isValidateOldPhone)?_c('div',{staticClass:"modify__item"},[_vm._m(0),_c('ul',{staticClass:"modify__sms"},[_c('li',{staticClass:"modify__sms-list"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPhone),expression:"newPhone"}],staticClass:"modify__sms--code passport-all__list-input",attrs:{"type":"tel","placeholder":'请输入手机号',"maxLength":11},domProps:{"value":(_vm.newPhone)},on:{"input":function($event){if($event.target.composing)return;_vm.newPhone=$event.target.value}}})]),_c('li',{staticClass:"modify__sms-list"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"modify__sms--code passport-all__list-input",attrs:{"type":"tel","placeholder":'请输入验证码',"maxLength":6},domProps:{"value":(_vm.code)},on:{"focus":function($event){_vm.inputFocus = true},"blur":_vm.inputBlur,"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}}),_c('a',{staticClass:"modify__sms-btn allbtn-xl",class:{
              'allbtn-green': !_vm.remainingSecond,
              'allbtn-gray': _vm.remainingSecond
            },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.sendCode(5)}}},[_vm._v(_vm._s(_vm.smsTextForNewPhone))]),(_vm.inputFocus)?_c('a',{staticClass:"modify__sms-close",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.clearCode}}):_vm._e(),(_vm.foremError)?_c('a',{staticClass:"modify__sms-error",attrs:{"href":"javascript:void(0)"}},[_vm._v("*"+_vm._s(_vm.foremError))]):_vm._e()])]),_c('div',{staticClass:"modify__btn"},[_c('a',{staticClass:"allbtn-xl allbtn-gray",class:{'allbtn-green': _vm.formValidate, 'allbtn-gray': !_vm.formValidate},attrs:{"href":"javascript:void(0)"},on:{"click":_vm.bindNewPhone}},[_vm._v("绑定")])])]):_vm._e()]),(_vm.showSuccessDialog)?_c('div',{staticClass:"alldialog"},[_c('div',{staticClass:"alldialog__box"},[_vm._m(1),_c('div',{staticClass:"alldialog__btn"},[_c('a',{staticClass:"alldialog__btn-link",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.goBack}},[_vm._v("确定")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modify__title"},[_c('div',{staticClass:"modify__title-word"},[_vm._v("绑定新手机号")]),_c('div',{staticClass:"modify__title-explain"},[_vm._v("为了您账户更加安全，请绑定新的手机号码")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alldialog__info"},[_c('div',{staticClass:"alldialog__info-text"},[_vm._v("手机号变更成功")])])
}]

export { render, staticRenderFns }