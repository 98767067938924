
import { Component, Vue, Provide, Emit, Watch } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import InputItem from '@/components/Input.vue'
import InvoiceModule from '@/store/modules/invoice'

@Component({
  name: 'invoiceTitle',
  mixins: [GlobalMixin],
  components: {
    'input-item': InputItem
  }
})
export default class extends Vue {
  @Provide() private item: any = {}
  @Provide() private isType: number = 1
  @Provide() private companyList: any = []
  @Provide() private personal: string = ''
  @Provide() private personalFocus: boolean = false
  @Provide() private company: string = ''
  @Provide() private companyFocus: boolean = false
  @Provide() private companyNumber: string = ''
  @Provide() private companyNumberFocus: boolean = false
  @Provide() private accountsBank: string = ''
  @Provide() private accountsBankFocus: boolean = false
  @Provide() private bankAccount: string = ''
  @Provide() private bankAccountFocus: boolean = false
  @Provide() private companyAddress: string = ''
  @Provide() private companyAddressFocus: boolean = false
  @Provide() private companyPhone: string = ''
  @Provide() private companyPhoneFocus: boolean = false
  @Provide() private pageIndex: number = 1
  @Provide() private pageSize: number = 4
  @Provide() private showCompanyList: boolean = false
  @Provide() private isLoadMore: boolean = false
  @Provide() private invoiceSysNo: number = 0
  @Provide() private invoiceType: number = 0
  @Provide() private isOptionalVisible = false
  @Provide() private from: any = ''
  @Provide() private eInvoiceEmail: any = ''

  public async created () {
    this.from = this.$route.query.from || ''
    const eCode: any = this.$route.query.eCode
    if (eCode) {
      const email = atob(eCode)
      const emailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]+$/
      if (emailReg.test(email)) {
        this.eInvoiceEmail = email
      }
    }
    this.currentRouteChanged()
    await InvoiceModule.loadInvoiceList()
    const invoiceList: any = InvoiceModule.invoice || []
    const invoice = invoiceList && this.invoiceSysNo ? invoiceList.find((invoice: any) => invoice.invoiceSysNo === this.invoiceSysNo) : {}
    this.isType = invoice.invoiceBelong || 1
    this.personal = invoice.invoiceTitle
    this.company = invoice.invoiceTitle
    this.companyNumber = invoice.invoiceTaxNo
    this.accountsBank = invoice.accountsBank
    this.bankAccount = invoice.bankAccount
    this.companyAddress = invoice.companyAddress
    this.companyPhone = invoice.companyPhone
  }

  get isOrderEnv () {
    return Boolean(this.from)
  }

  private mounted () {
    document.addEventListener('click', this.closeTip)
  }

  @Watch('$route')
  private currentRouteChanged () {
    const { id, type } = this.$route.params
    this.invoiceSysNo = parseInt(id) || 0
    this.invoiceType = parseInt(type) || 0
  }

  private backOrder () {
    window.location.replace(`/invoiceSet?from=${this.from}`)
  }

  @Emit()
  private selectType (type: number) {
    this.isType = type
  }

  @Emit()
  private handleInput (value: string) {
    console.log(value)
  }

  @Emit()
  private inputBlur (event: any) {
    const { key } = event.target.dataset
    setTimeout(() => {
      switch (key) {
        case 'personal':
          this.personalFocus = false
          return
        case 'company':
          this.companyFocus = false
          return
        case 'companyNumber':
          this.companyNumberFocus = false
          return
        case 'accountsBank':
          this.accountsBankFocus = false
          return
        case 'bankAccount':
          this.bankAccountFocus = false
          return
        case 'companyAddress':
          this.companyAddressFocus = false
          return
        case 'companyPhone':
          this.companyPhoneFocus = false
      }
    }, 150)
  }

  @Emit()
  private async seachCompany () {
    try {
      const res = await httpHelper.get({
        url: 'Invoice/Search',
        data: {
          keyword: this.company,
          more: this.isLoadMore,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        },
        type: 'unify'
      })
      this.companyList = res.value || []
      this.showCompanyList = !(this.companyList.length === 0 && this.isLoadMore)
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  @Emit()
  private loadMore () {
    this.isLoadMore = true
    this.seachCompany()
  }

  @Emit()
  private closeTip () {
    this.showCompanyList = false
  }

  @Emit()
  private async choiceCompany (item: any) {
    this.company = item.enterpriseName
    this.companyNumber = item.creditCode
    this.showCompanyList = false
    this.isLoadMore = false
  }

  @Emit()
  private handleClear (value: string) {
    switch (value) {
      case 'personal':
        this.personal = ''
        return
      case 'company':
        this.company = ''
        return
      case 'companyNumber':
        this.companyNumber = ''
    }
  }

  @Emit()
  private async saveinvoice () {
    const self = this
    const data: any = {
      invoiceBelong: self.isType,
      invoiceTitle: self.isType === 1 ? self.personal : self.company,
      invoiceTaxNo: self.isType === 1 ? '' : self.companyNumber,
      invoiceSysNo: self.invoiceSysNo,
      invoiceType: self.invoiceType,
      accountsBank: self.isType === 1 ? '' : self.accountsBank,
      bankAccount: self.isType === 1 ? '' : self.bankAccount,
      companyAddress: self.isType === 1 ? '' : self.companyAddress,
      companyPhone: self.isType === 1 ? '' : self.companyPhone
    }
    if (self.eInvoiceEmail) {
      data.eInvoiceEmail = self.eInvoiceEmail
    }

    let url = 'IUserHome/Invoice'
    // 随单票接口不同
    if (this.isOrderEnv) {
      url = 'IUserHome/InvoiceForM'
    }
    try {
      const res = await httpHelper.post({
        url,
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        await InvoiceModule.loadInvoiceList()
        if (self.invoiceSysNo !== 0) {
          self.$toasted.show('修改抬头成功')
        } else {
          self.$toasted.show('新增抬头成功')
        }
        const list = InvoiceModule.invoice || []
        // 新增发票title，发票的invoiceSysNo为0，导致设置发票信息页面currentInvoice拿不到
        self.invoiceSysNo = self.invoiceSysNo ? self.invoiceSysNo : list[0].invoiceSysNo
        InvoiceModule.setInvoice(self.invoiceSysNo)
        console.log(self.invoiceSysNo)
        setTimeout(() => {
          if (this.isOrderEnv) {
            window.location.replace(`/invoiceSet?from=${this.from}&refresh=true`)
          } else {
            self.$router.back()
          }
        }, 1500)
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }
}

