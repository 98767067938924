
import { Component, Vue, Emit, Provide } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'invoiceMsg',
  mixins: [GlobalMixin]
})
export default class extends Vue {
  @Provide() private msg: any = ''
  private created () {
    this.refresh()
  }

  @Emit()
  private async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'pact?type=2',
        type: 'apiv5'
      })
      this.msg = res.data
    } catch (err) {
      console.log(err)
    } finally {
      console.log('123456')
    }
  }
}

