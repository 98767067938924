
import { Component, Prop, Vue, Emit, Provide, Watch } from 'vue-property-decorator'

@Component
export default class InputItem extends Vue {
  @Prop({ type: String, default: '' })
  private readonly value!:string

  @Prop({ type: String, default: '' })
  private placeholder!: string

  @Prop({ type: String, default: 'auth__list' })
  private blockClassName!: string

  @Prop({ type: String, default: 'text' })
  private type!: string

  @Prop({ type: Number, default: 999 })
  private maxlength!: number

  @Provide()
  private val: string = this.value

  @Provide()
  private focus: boolean = false

  get ph () {
    return this.placeholder
  }

  get inputType () : string {
    return this.type
  }

    @Watch('focus')
  private focusChanged () {
    this.$emit('focusChanged', this.focus)
  }

    private resolveClassName (elementName: string) {
      return `${this.blockClassName}-${elementName}`
    }

  @Emit()
    private handleInput () {
      console.log(this.val)
      this.$emit('input', this.val)
    }

  @Emit()
  private handleClear () {
    this.val = ''
    this.$emit('input', this.val)
  }

  @Emit()
  private async handleBlur () {
    setTimeout(() => { this.focus = false }, 50)
  }
}
