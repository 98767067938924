
import { Component, Vue, Provide, Emit, Watch } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import AddressModule from '@/store/modules/address'
import InvoiceModule from '@/store/modules/invoice'
import { setSelectedAddress } from '@/utils/cookies'
import Multiple from '@/components/invoice/Multiple.vue'
import Clipboard from 'clipboard'

@Component({
  name: 'invoiceCenter',
  mixins: [GlobalMixin],
  components: {
    'invoice-multiple': Multiple
  }
})
export default class extends Vue {
  private soId = ''
  private invoiceData = {}
  private orderInfo = { products: [], orderTotalAmt: '¥0.00' }
  private isEmailVisible = false
  private email = ''

  @Watch('$route')
  private currentRouteChanged () {
    const { id } = this.$route.params
    this.soId = id
  }

  private created () {
    this.currentRouteChanged()
    this.getInvoiceInfo()
  }

  private async getInvoiceInfo () {
    try {
      const res = await httpHelper.get({
        url: 'Invoice/Detail',
        data: {
          soId: this.soId
        },
        type: 'apiv5'
      })
      console.log(res)
      this.invoiceData = res.data
      this.email = res.data.invoiceEmail
      if (res.data.orderInfo) {
        this.orderInfo = res.data.orderInfo
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  private copySysNo (e: any) {
    let clipboardObj = new Clipboard(e.target)
    clipboardObj.on('success', (e) => {
      this.$toasted.show('已复制')
      clipboardObj.destroy()
    })
  }

  private dotAmtFilter (amt: string) {
    const str = amt.match(/\d+/g) || ['0', '00']
    return {
      int: str[0],
      dec: str[1] || '00'
    }
  }

  private confirmSendEmail () {
    this.isEmailVisible = true
  }

  private async sendEmail () {
    this.isEmailVisible = false
    const opt: any = {
      orderId: this.soId
    }
    if (this.email) {
      opt.email = this.email
    }
    try {
      const res = await httpHelper.post({
        url: 'IOrder/ResendInvoiceEmail',
        data: opt,
        type: 'apiv5'
      })
      this.$toasted.show(res.data)
    } catch (err) {
      this.showErrorToast(err)
    }
  }
}

