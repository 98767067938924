
import { Component, Vue, Provide, Emit, Watch } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import AddressModule from '@/store/modules/address'
import InvoiceModule from '@/store/modules/invoice'
import { setSelectedAddress } from '@/utils/cookies'

@Component({
  name: 'invoice',
  mixins: [GlobalMixin]
})
export default class extends Vue {
  @Provide() private id: number = 0
  @Provide() private soSysNo: number = 0
  @Provide() private soType: string = ''
  @Provide() private orderId: string = ''
  @Provide() private isShowTips: boolean = false
  @Provide() private invoicetype: number = 0
  @Provide() private invoiceindex: number = 0
  @Provide() private typeList: any = {}
  @Provide() private list: any = []
  @Provide() private invoiceTitle: string = ''
  @Provide() private invoiceTaxNo: string = ''
  @Provide() private email: string = ''
  @Provide() private invoiceBelong: number = 1

  private created () {
    this.soSysNo = getCurrentQuery('soSysNo')
    this.soType = getCurrentQuery('soType')
    this.orderId = getCurrentQuery('soId')
  }

  private async mounted () {
    this.refresh()
    await InvoiceModule.loadInvoiceList()
    this.list = InvoiceModule.invoice
  }

  private activated () {
    console.log(this.invoiceBelong)
  }

  get selectedAddress () {
    return AddressModule.selectedAddress
  }

  get selectInvoiceSysNo () {
    return InvoiceModule.invoiceSysNo
  }

  get currentInvoice () {
    const invoiceList: any = InvoiceModule.invoice || []
    const invoice = invoiceList && this.selectInvoiceSysNo ? invoiceList.find((invoice: any) => invoice.invoiceSysNo === this.selectInvoiceSysNo) : {}
    return invoice
  }

  get currentAddress () {
    return AddressModule.currentAddress
  }

  @Watch('currentInvoice')
  private async selectInvoiceChanged (val: any) {
    this.invoiceBelong = val.invoiceBelong ? val.invoiceBelong : this.invoiceBelong
    this.invoiceTitle = val.invoiceTitle
    this.invoiceTaxNo = val.invoiceTaxNo
    this.list = InvoiceModule.invoice
  }

  @Watch('currentAddress')
  private selectAddressChanged (val: any) {
    this.typeList.receiveAddress.receiveName = val.receiveContact
    this.typeList.receiveAddress.receiveCellPhone = val.receiveCellPhone
    this.typeList.receiveAddress.receiveInvoiceAddress = val.receiveAreaName + ' ' + val.receiveAddress
  }

  @Emit()
  private async refresh () {
    const self = this
    try {
      const res = await httpHelper.get({
        url: 'IOrder/InvoiceType',
        data: {
          soSysNo: self.soSysNo,
          soType: self.soType,
          orderId: self.orderId
        },
        type: 'apiv5'
      })
      const result = res.data || {}
      this.typeList = result
      const defaultInvoice = result.defaultInvoice || {}
      this.invoiceBelong = defaultInvoice.invoiceBelong || 1
      this.invoiceTaxNo = defaultInvoice.invoiceTaxNo || ''
      this.invoiceTitle = defaultInvoice.invoiceTitle || ''
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  @Emit()
  private selectType (index:number) {
    if (Object.keys(this.typeList.invoiceList).length) {
      for (const item of this.typeList.invoiceList) {
        item.checked = false
      }
      this.typeList.invoiceList[index].checked = true
      this.invoicetype = this.typeList.invoiceList[index].type // 赋值选中类别
      this.invoiceindex = index // 赋值选中索引
    }
  }

  @Emit()
  private openTips () {
    this.isShowTips = true
  }

  @Emit()
  private closeTips () {
    this.isShowTips = false
  }

  @Emit()
  private selectTitle (item:any = {}) {
    this.invoiceTaxNo = item.invoiceTaxNo || ''
    this.invoiceTitle = item.invoiceTitle || ''
    this.invoiceBelong = item.invoiceBelong || 1
    this.closeTips()
  }

  @Emit()
  private openEdit (sysNo: number) {
    this.isShowTips = false
    this.$router.push({ path: `/invoiceTitle/${sysNo}/${this.invoicetype}` })
  }

  @Emit()
  private async submitInvoice () {
    try {
      const res = await httpHelper.post({
        url: 'IOrder/SubmitInvoiceSetting',
        data: {
          soSysNo: this.soSysNo,
          soType: this.soType,
          orderId: this.orderId,
          invoiceType: this.invoicetype,
          requestInvoiceType: this.invoiceBelong,
          invoiceContent: this.invoiceTitle,
          taxPayerNo: this.invoiceTaxNo,
          addressSysNo: this.selectedAddress || '',
          receiveAddress: this.typeList.receiveAddress.receiveInvoiceAddress || '',
          receivePhone: this.typeList.receiveAddress.receiveCellPhone || '',
          receiveContact: this.typeList.receiveAddress.receiveName || '',
          receiveEmail: this.email || ''
        },
        type: 'apiv5'
      })
      AddressModule.setAddress(0)
      InvoiceModule.setInvoice(0)
      this.$toasted.show('开票成功')
      setTimeout(() => {
        this.$router.go(-1)
      }, 1500)
    } catch (err) {
      AddressModule.setAddress(0)
      InvoiceModule.setInvoice(0)
      this.showErrorToast(err)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1500)
    }
  }
}

